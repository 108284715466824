<template>
  <header
    v-if="router.path !== '/checkout'"
    class="bg-white sm:z-40 md:z-50 z-30 sticky w-full -top-1 transition-all"
    :class="{ 'lg:-top-5': !showTopBar }"
    id="header"
  >
    <NuxtLayout
      name="stretch"
      class="topBar hidden lg:block pt-1"
      :class="{ 'lg:is-hidden': !showTopBar }"
    >
      <div class="flex flex-wrap items-center mx-auto justify-end">
        <div class="flex md:order-2">
          <CommonCallNumber
            headerClass="text-[13px]"
            callClass="text-secondary font-bold"
          />
        </div>
      </div>
    </NuxtLayout>

    <div
      class="fixed lg:hidden top-0 w-full bg-grey-light z-[555] ease-in-out duration-200"
      :class="isOpen ? 'translate-x-0 ' : 'translate-x-full  '"
    >
      <div class="bg-white">
        <div class="py-4 px-3 justify-center flex border-b">
          <NuxtLinkLocale
            :locale="switchBlogLocale()"
            class="flex items-center"
            to="/"
          >
            <NuxtImg
              loading="lazy"
              src="/img/logo.svg"
              class="w-44"
              alt="Better2Know"
              width="250"
              height="100"
            />
          </NuxtLinkLocale>

          <button class="">
            <XIcon
              @click="close"
              class="w-7 h-7 absolute top-[18px] right-4 text-black"
            />
          </button>
        </div>
        <!-- MAIN MENU  -->
        <MenuMobile
          v-if="isOpen"
          :allSites="allSites"
          :close-menu="close"
          :isOpen="isOpen"
          class="h-screen z-[4]"
        />
      </div>
    </div>

    <nav class="w-full p-4 lg:py-0 xl:p-0 mx-auto md:max-w-7xl justify-center">
      <div class="flex flex-wrap justify-between">
        <NuxtLinkLocale
          :locale="switchBlogLocale()"
          class="flex items-center"
          to="/"
        >
          <NuxtImg
            loading="lazy"
            src="/img/logo.svg"
            class="w-44 md:w-52 lg:w-60 max-w-full"
            alt="Better2Know"
            width="250"
            height="100"
          />
        </NuxtLinkLocale>

        <div class="flex md:order-1 space-x-6">
          <!--<button class="md:hidden">
            <NuxtImg src="/icons/search.svg" alt="search" />
          </button>
          <button class="md:hidden">
            <NuxtImg src="/icons/shopping-cart.svg" alt="cart" />
          </button>
          -->
          <button @click="showMenu" class="mt-1 lg:hidden">
            <NuxtImg
              loading="lazy"
              src="/icons/menu-icon.svg"
              alt=""
              class="w-9 h-9"
              width="32"
              height="32"
            />
          </button>
        </div>

        <div
          class="hidden ml-auto lg:flex md:w-auto md:order-2 mx-4"
          id="mobile-menu-3"
        >
          <ul
            class="flex flex-col md:flex-row md:space-x-16 text-lg font-semibold"
          >
            <LayoutMegaMenuAnchor to="/get-started">
              {{ $t("mainmenu.getStarted") }}
            </LayoutMegaMenuAnchor>
            <LayoutMegaMenuAnchor to="/clinics"> {{ $t("mainmenu.clinics") }} </LayoutMegaMenuAnchor>
            <LayoutMegaMenuAnchor to="/tests" anchor-key="tests">
              {{ $t("mainmenu.tests") }}
            </LayoutMegaMenuAnchor>

            <LayoutMegaMenuAnchor
              to="/help-and-advice"
              anchor-key="help-and-advice"
            >
              {{ $t("mainmenu.helpAndAdvice") }}
            </LayoutMegaMenuAnchor>
          </ul>
        </div>

        <div
          class="hidden items-center w-full min-h-[56px] lg:flex md:w-auto md:space-x-8 m-4 md:order-2"
          id="mobile-menu-2"
        >
          <NuxtLinkLocale
            :locale="switchBlogLocale()"
            :to="globalStore.patientLoginLink"
            target="_blank"
          >
            <button>
              <NuxtImg
                loading="lazy"
                src="/icons/profile.svg"
                alt="Patient Login"
                width="22"
                height="25"
              />
            </button>
          </NuxtLinkLocale>
          <!--<button
            type="button"
            data-collapse-toggle="mobile-menu-2"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
            class="text-gray-500 focus:outline-none focus:ring-4 focus:ring-gray-200 rounded-lg text-sm"
          >
            <NuxtImg src="/icons/search.svg" alt="" />
          </button>
          <button>
            <NuxtImg src="/icons/shopping-cart.svg" alt="" />
          </button>-->
          <Menu
            v-if="!router.path.includes('/blog')"
            :allSites="allSites"
            class="z-[4]"
          />
        </div>
      </div>
    </nav>
    <div class="w-full bg-gray-200 h-0.5">
      <CommonProgressBar v-if="commonBar.includes(getLocaleUrl)" />
    </div>

    <!-- Adding Mega menu to the layout -->
    <LayoutMegaMenu />
  </header>
</template>

<script setup>
import { XIcon } from "@heroicons/vue/solid";
import { useGlobalStore } from "@/stores/global";

const globalStore = useGlobalStore();
const router = useRoute();
const getLocaleUrl = computed(() => {
  const splitUrl = router.name?.split("___");
  return splitUrl?.length > 0 ? splitUrl[0] : router.name;
});

const isOpen = ref(false);
const showTopBar = ref(true);
const lastScrollPosition = ref(0);
const scrollOffset = ref();
const allSites = ref({});
allSites.value = globalStore.siteAllLocales;

onMounted(() => {
  lastScrollPosition.value = window.pageYOffset;
  window.addEventListener("scroll", onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", onScroll);
});

const showMenu = () => {
  document.body.classList.toggle("overflow-hidden");
  isOpen.value = true;
};
const close = () => {
  document.body.classList.toggle("overflow-hidden");
  isOpen.value = false;
};

const onScroll = () => {
  let bottomOfWindow =
    Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    ) +
      window.innerHeight ===
    document.documentElement.offsetHeight;

  if (window.pageYOffset < 150) {
    return;
  }
  if (
    Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value
  ) {
    return;
  }

  showTopBar.value =
    window.pageYOffset < lastScrollPosition.value || bottomOfWindow;
  lastScrollPosition.value = window.pageYOffset;
};

const commonBar = ["blog-slug", "help-and-advice-category-slug"];
</script>

<!-- Other scoped styles -->
<style scoped>
.topBar {
  transform: translateY(0);
  transition: transform 400ms ease;
}

.topBar.is-hidden {
  transform: translateY(-100%);
}
</style>
