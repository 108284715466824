<template>
  <div>
    <NuxtImg
      loading="lazy"
      :src="getImage"
      :alt="`${item.name ?? ''} icon`"
      class="w-5 mr-2"
      height="32"
      width="32"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  item: Object,
});

const getImage = computed(
  () => new URL(`/assets/icons/flags/${props.item.flag}`, import.meta.url).href
);
</script>
