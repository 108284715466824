<template>
  <footer
    v-if="router.path !== '/checkout'"
    class="border-t-[8px] border-primary"
  >
    <div class="bg-grey-light py-8">
      <NuxtLayout name="center">
        <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div class="flex shrink-0 justify-start w-full md:w-auto">
            <NuxtLinkLocale
              :locale="switchBlogLocale()"
              class="flex items-center"
              to="/"
            >
              <NuxtImg
                loading="lazy"
                src="/img/logo.svg"
                class="w-44 md:w-60 max-w-full"
                :alt="globalStore.siteSettings.site_title"
                width="250"
                height="100"
              />
            </NuxtLinkLocale>
          </div>
          <div class="flex flex-col mt-10 md:mt-0 ml-0 md:ml-4">
            <div class="flex">
              <PhoneIcon class="w-8 h-8 mr-1 shrink-0 " />
              <CommonCallNumber
                keyPath="phoneCall.footer.title"
                tag="div"
                headerClass="flex flex-col md:flex-row"
                callClass="text-secondary font-black text-lg md:mr-1"
              />
            </div>
            <div class="flex md:justify-end mt-2 -mb-3">
              <Menu
                v-if="!router.path.includes('/blog')"
                :allSites="allSites"
                position="footer"
                class="z-[4]"
              />
            </div>
          </div>
        </div>
      </NuxtLayout>
    </div>
    <div class="bg-[#EDEDED] md:bg-grey-light">
      <NuxtLayout name="center">
        <div
          class="w-full pt-8 pb-5 grid grid-cols-2 gap-4 sm:gap-6 auto-cols-max md:grid-cols-4"
        >
          <div
            v-for="(subOptions, index) in footerOptions"
            :key="index"
            :class="{ ' ': index == 'Support' }"
          >
            <div class="mb-2 font-bold text-dark-blue">{{ subOptions.label }}</div>
            <ul class="mb-6 md:mb-0">
              <li
                class="mb-3"
                v-for="(subOption, index) in subOptions.items"
                :key="index"
              >
                <NuxtLinkLocale
                  :locale="switchBlogLocale(subOption.url == '/blog')"
                  :to="subOption.url"
                >
                  {{ subOption.name }}
                </NuxtLinkLocale>
              </li>
            </ul>
          </div>
        </div>
      </NuxtLayout>
    </div>
    <div class="bg-[#DEDEDE] md:bg-[#EDEDED] py-6 md:py-8">
      <NuxtLayout name="center">
        <ClientOnly>
          <div class="grid grid-cols-none md:grid-cols-3">
            <div class="pr-8 flex items-start mb-9 md:mb-0">
              <div class="flex items-end">
                <NuxtImg
                  :src="(globalStore.siteisAustralia)?'/img/medical-advisor.jpg':'/img/photo_steve-pierini.jpg'"
                  class="w-[80px] mr-4 rounded-[5px]"
                  :alt="$t('footer.medicalAdvisorName')"
                  width="80"
                  height="80"
                />
                <div class="">
                  <div class="mb-1.5 font-bold text-dark-blue">
                    {{ $t("footer.medicalAdvisor") }}
                  </div>
                  <p class="text-dark-blue mt-2">
                    {{ $t("footer.medicalAdvisorName") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pr-8 lg:pr-12 mb-14 md:mb-0">
              <p class="text-blog-14-sm text-dark-blue italic">
                {{ $t("footer.acknowledgement") }}
              </p>
            </div>
            <div
              class="flex flex-col flex-nowrap justify-end items-center md:items-end w-100"
            >
              <div class="flex flex-wrap justify-end items-center space-x-4">
                <NuxtImg
                  loading="lazy"
                  src="/img/icon_iso9001.png"
                  class="h-[70px]"
                  alt="ISO 9001"
                  width="120"
                  height="70"
                />
                <NuxtImg
                  loading="lazy"
                  src="/img/CNB-Business.png"
                  class="w-[75px]"
                  alt="Carbon Neutral Britan"
                  width="75"
                  height="75"
                />
              </div>
              <div class="flex justify-end items-center gap-8 pt-5 mr-6">
                <NuxtImg
                  loading="lazy"
                  src="/img/visa.svg"
                  class=""
                  alt="visa"
                  width="46"
                  height="34"
                />
                <NuxtImg
                  loading="lazy"
                  src="/img/mastercard.svg"
                  class=""
                  alt="mastercard"
                  width="46"
                  height="34"
                />
                <NuxtImg
                  loading="lazy"
                  src="/img/amex.svg"
                  class=""
                  alt="amex"
                  width="46"
                  height="34"
                />
                <NuxtImg
                  loading="lazy"
                  src="/img/privacy.svg"
                  class=""
                  alt="privacy"
                  width="27"
                  height="32"
                />
                <!--<NuxtImg   loading="lazy"
  src="/img/android-pay.svg" class="" alt="android-pay" />
                <NuxtImg src="/img/apple-card.svg" class="" alt="apple-card" />-->
              </div>
            </div>
          </div>
        </ClientOnly>
      </NuxtLayout>
    </div>
    <div class="bg-[#505F76] py-3">
      <NuxtLayout name="center">
        <div class="flex items-center gap-2 justify-center ">          
          <span class="text-white text-sm">
            {{
              $t("footer.copyRightTitle", { year: new Date().getFullYear() })
            }}
          </span>
          <span class="server-presentation" aria-hidden="true" v-if="!!server" :title="server.targetServer">{{ server.badgeLabel }}</span>
        </div>
      </NuxtLayout>
    </div>
  </footer>
</template>

<script setup>
import { useGlobalStore } from "~/stores/global";
import { PhoneIcon } from "@heroicons/vue/outline";

const runtimeConfig = useRuntimeConfig();
const server = computed(() => {
const targetServer = runtimeConfig.public.targetServer;
if( !targetServer ) return undefined;

return ({
  targetServer,
  badgeLabel: targetServer.charAt(0)
  })
})

const router = useRoute();
const globalStore = useGlobalStore();
const path = ref();
path.value = router.path.split("/").splice(2, 1);
const { t } = useI18n();
const allSites = ref({});
allSites.value = globalStore.siteAllLocales;

// watch(router, () => {
//   path.value = router.path.split("/").splice(2, 1);
// });


const companyItems = [
  {
    name: t("footer.menu.item.AboutUs"),
    url: "/about-us",
  },
  {
    name: t("footer.menu.item.InTheMedia"),
    url: "/about-us/in-the-media",
  },
  {
    name: t("footer.menu.item.termsAndConditions"),
    url: "/legal-and-privacy",
  }
];

if (globalStore.siteSettings.display_blog == true) {
  const blogItem = {
    name: t("footer.menu.item.Blog"),
    url: "/blog",
  };
  companyItems.splice(1, 0, blogItem);
}

const footerOptions = {
  companyInfo: {
    label: t("footer.menu.label.CompanyInfo"),
    items: companyItems,
  },
  support: {
    label: t("footer.menu.label.Support"),
    items: [
      {
        name: t("footer.menu.item.ContactUs"),
        url: "/contact-us",
      },
      {
        name: t("footer.menu.item.HelpAdvice"),
        url: "/help-and-advice",
      },
      {
        name: t("footer.menu.item.FAQs"),
        url: "/faqs",
      },
    ]
  },
  healthTests: {
    label: t("footer.menu.label.HealthTests"),
    items: [
      {
        name: t("footer.menu.item.SexualHealth"),
        url: "/help-and-advice/sexual-health-and-stis",
      },
      {
        name: t("footer.menu.item.ChooseYourTest"),
        url: "/tests",
      },
    ],
  },
  clinics: {
    label: t("footer.menu.label.Clinics"),
    items: [
      {
        name: t("footer.menu.item.FindClinic"),
        url: "/clinics",
      },
    ],
  }
};

</script>

<style lang="scss">
  .server-presentation {
    @apply relative grid place-items-center bg-space-cadet opacity-20 text-white text-sm font-semibold aspect-square;
    width: 2em;
    border-radius: 50%;
  }
</style>
